import { Injectable } from '@angular/core';
import { DPOApiService } from './dpo.api.service';
import { CompanyMobilePaymentOptionsResponseType, TerminalMobile } from '../models/types/company.mobile.payment.option.response.type';
import { CompanyMobilePaymentOptions } from '../models/requests/company.mobile.payment.options.request.model';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';
import { availableCountries } from 'src/app/static-data/mno.countries';

@Injectable({
    providedIn: 'root'
})
export class MobilePaymentService {

    private mnoTerminals: TerminalMobile[] = new Array;
    private validMNOs: any[] = new Array;

    constructor(private apiService: DPOApiService,
        private sessionService: SessionService) { }

    setupMobilePaymentOptions() {
        this.getMobilePaymentOptions(this.sessionService.getCompanyToken()).subscribe((options => {
           if (options.API3G.Result !== '904') {
               //  The DPO API does not always return a list (for whatever reason) when only one item is returned, its not a list with length of '1'
               //  but instead an object, this breaks the filter process. So here I cater for that and ensure it always end up as a list.
               if(options.API3G.paymentoptionsmobile.terminalmobile.length) {
               this.mnoTerminals = options.API3G.paymentoptionsmobile.terminalmobile;
            } else {
               this.mnoTerminals.push(options.API3G.paymentoptionsmobile.terminalmobile as any);
            }
             
            this.mapMobilePaymenyOptions();
           }
        }))
    }

    getValidMNOs() {
        return this.validMNOs;
    }

    private getMobilePaymentOptions(companyToken: string): Observable<CompanyMobilePaymentOptionsResponseType> {
        return this.apiService.HTTPPost(new CompanyMobilePaymentOptions(companyToken));
    }

    private mapMobilePaymenyOptions() {

        this.validMNOs = new Array;

        availableCountries.forEach(item => {
            
            const terminals = this.mnoTerminals.filter(t => t.terminalmnocountry.toLowerCase() === item.name.toLowerCase());

            if (terminals.length > 0) {

                let validMNO = {
                    name: item.name,
                    providers: []
                }

               item.providers.forEach(provider => {

                   const match = terminals.find(t => t.terminalmno.toLowerCase() === provider.terminalMno.toLowerCase());
                   if (match) {
                       validMNO.providers.push(provider);
                   }
                   
               });

               if (validMNO.providers.length > 0) {
                this.validMNOs.push(validMNO);
               }
               
            }
        });
    }

}
