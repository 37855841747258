import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session.service';
import { Router } from '@angular/router';
import { MobilePaymentService } from 'src/app/services/mobile.payment.service';
import { airports } from 'src/app/static-data/airports.data';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {

    public activeUserName: string;
    public companyBrandName: string;

    constructor(private sessionService: SessionService,
        private router: Router,
        private mobilePaymentsService: MobilePaymentService) { }

    ngOnInit() {
        this.activeUserName = this.sessionService.getUserName();
        this.companyBrandName = this.sessionService.getCompanyBrandName();

        this.mobilePaymentsService.setupMobilePaymentOptions();
    }

    logoutUser() {
        this.sessionService.clearUserSession();
        this.router.navigate(['/login']);
    }

}
