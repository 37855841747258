import { Component, OnInit, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { ROUTES, RouteInfo } from '../sidebar/sidebar.component';
import { Location, } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

    @Input()activeUserName;
    @Output()onLoggoutClicked: EventEmitter<any> = new EventEmitter();

    public focus;
    public listTitles: RouteInfo[];
    public location: Location;

    public userName: string;

    constructor(location: Location, private element: ElementRef) {
        this.location = location;
    }

    ngOnInit() {
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        this.userName = this.activeUserName;
    }
    getTitle() {
        const urlParts = this.location.prepareExternalUrl(this.location.path()).split('/');
        const title = urlParts[1];

        const item = this.listTitles.find(item => item.path === `/${title}`);
        return item ? item.title : '';
    }

    onLogOutClick() {
        this.onLoggoutClicked.emit(null);
    }

}
