<footer class="footer">
    <div class="row align-items-center justify-content-xl-between">        
        <div class="col">
            <ul class="nav nav-footer justify-content-center justify-content-xl-end">
                <li class="nav-item">
                    <a href="https://www.directpay.online" class="nav-link" target="_blank">Group Website</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" target="_blank">For T &amp; C's log into the merchant portal</a>
                </li>
                <li class="nav-item">
                    <a href="https://www.directpay.online/general-data-protection-regulation-gdpr/" class="nav-link"
                        target="_blank">GDPR</a>
                </li>
                <li class="nav-item">
                    <a href="https://www.directpay.online/support/" class="nav-link" target="_blank">Support</a>
                </li>
            </ul>
        </div>
    </div>
</footer>