export enum ToastFrom {
    TOP = 'top',
    BOTTOM = 'bottom'
}

export enum ToastAlign {
    LEFT = 'left',
    CENTER = 'center',
    RIGHT = 'right',
}
