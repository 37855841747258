<nav class="navbar navbar-vertical navbar-expand-md navbar-light bg-white" id="sidenav-main">
  <div class="container-fluid">
    <!-- Toggler -->
    <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed"
       aria-controls="sidenav-collapse-main">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Brand -->
    <a class="navbar-brand pt-0 pb-0" routerLinkActive="active" [routerLink]="['/payment']">
      <img src="./assets/img/brand/main-brand-logo.png" class="navbar-brand-img" alt="...">      
    </a>
    <p class="brand-prompt mt-3">{{companyNamePrompt}}</p>
    <!-- User -->
    <ul class="nav align-items-center d-md-none">      
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="./assets/img/brand/pp.jpg">
            </span>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <div class=" dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <a routerLinkActive="active" [routerLink]="['/payments']" class="dropdown-item">
            <i class="ni ni-money-coins text-primary"></i>
            <span>Create Payment</span>
          </a>
          <a routerLinkActive="active" [routerLink]="['/transactions']" class="dropdown-item">
            <i class="ni ni-book-bookmark text-primary"></i>
            <span>Payments Log</span>
          </a>         
          <div class="dropdown-divider"></div>
          <a href="#!" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </a>
        </div>
      </li>
    </ul>
    <!-- Collapse -->
    <div class="collapse navbar-collapse"  [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
      <!-- Collapse header -->
      <div class="navbar-collapse-header d-md-none">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a  routerLinkActive="active" [routerLink]="['/payment']">
              <img src="./assets/img/brand/DPO Group Logo Small.png">
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button type="button" class="navbar-toggler" (click)="isCollapsed=!isCollapsed">
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <!-- Navigation -->
      <ul class="navbar-nav">
          <li *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
              <a *ngIf="menuItem.shouldShowInNav" routerLinkActive="active" [routerLink]="[menuItem.path]" class="nav-link">
                  <i class="ni {{menuItem.icon}}"></i>
                  {{menuItem.title}}
              </a>
          </li>
      </ul>

    </div>
  </div>
</nav>
