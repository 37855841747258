import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private toastr: ToastrService) { }

    showInfo(message: string, from: string, align: string ) {
        this.show(message, from, align, 'info', 'Note');
    }

    showSuccess(message: string, from: string, align: string ) {
        this.show(message, from, align, 'success', 'Operation Completed');
    }

    showWarning(message: string, from: string, align: string ) {
        this.show(message, from, align, 'warning', 'Warning');
    }

    showError(message: string, from: string, align: string ) {
        this.show(message, from, align, 'danger', 'Error');
    }

    private show(message: string, from: string, align: string, type: string, prompt: string) {
        this.toastr.show(
            `<strong>${prompt}</strong>: ${message}`, '', {
            timeOut: 6000,
            closeButton: true,
            enableHtml: true,
            toastClass: `alert alert-${type} alert-with-icon`,
            positionClass: 'toast-' + from + '-' +  align
          });

    }

}
// `<span class="ni ni-book-bookmark"></span>${message}`