export const availableCountries = [
    {
        name: 'Kenya',
        providers: [
            {
                name: 'Mpesa',
                terminalMno: 'SafaricomSTKv2',
                imgURL: 'https://s3-eu-west-1.amazonaws.com/directpaystorage/icons/mpesa.png'
            },
            {
                name: 'Airtel',
                terminalMno: 'airtel',
                imgURL: 'https://s3-eu-west-1.amazonaws.com/directpaystorage/icons/airtel.png'
            }
        ]
    },
    {
        name: 'Tanzania',
        providers: [
            {
                name: 'Tigo',
                terminalMno: 'TIGOdebitMandate',
                imgURL: 'https://s3-eu-west-1.amazonaws.com/directpaystorage/icons/tigo.png'
            },
            {
                name: 'Vodacom',
                terminalMno: 'vodacom',
                imgURL: 'https://s3-eu-west-1.amazonaws.com/directpaystorage/icons/vodacom.png'
            },
            {
                name: 'Airtel',
                terminalMno: 'airtel',
                imgURL: 'https://s3-eu-west-1.amazonaws.com/directpaystorage/icons/airtel.png'
            }
        ]
    },
    {
        name: 'Uganda',
        providers: [
            {
                name: 'MTN',
                terminalMno: 'MTNmobilemoney',
                imgURL: 'https://s3-eu-west-1.amazonaws.com/directpaystorage/icons/mtn.png'
            },
            {
                name: 'Airtel',
                terminalMno: 'AirtelMoney_Jumia',
                imgURL: 'https://s3-eu-west-1.amazonaws.com/directpaystorage/icons/airtel.png'
            }
        ]
    },
    {
        name: 'Ghana',
        providers: [
            {
                name: 'MTN',
                terminalMno: 'mtn',
                imgURL: 'https://s3-eu-west-1.amazonaws.com/directpaystorage/icons/mtn.png'
            }
        ]
    },
    {
        name: 'Rwanda',
        providers: [
            {
                name: 'MTN',
                terminalMno: 'mtn',
                imgURL: 'https://s3-eu-west-1.amazonaws.com/directpaystorage/icons/mtn.png'
            }
        ]
    },
    {
        name: 'Ivory Coast',
        providers: [
            {
                name: 'Orange',
                terminalMno: 'Orange_MoneyWebPay',
                imgURL: 'https://s3-eu-west-1.amazonaws.com/directpaystorage/icons/orange.png'
            },
            {
                name: 'MTN',
                terminalMno: 'MTNbillmap',
                imgURL: 'https://s3-eu-west-1.amazonaws.com/directpaystorage/icons/mtn.png'
            }
        ]
    }

]
