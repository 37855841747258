<!-- Sidenav -->
<app-sidebar companyBrandName={{companyBrandName}}></app-sidebar>
<div class="main-content">
  <!-- Top navbar -->
  <app-navbar activeUserName={{activeUserName}} (onLoggoutClicked)="logoutUser()"></app-navbar>
  <!-- Pages -->
  <router-outlet></router-outlet>
  <div class="container-fluid">
    <app-footer></app-footer>
  </div>
</div>
