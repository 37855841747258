<div class="main-content">

    <router-outlet></router-outlet>
</div>
<footer class="py-5">
    <div class="container">
        <div class="row align-items-center justify-content-xl-between">
            <div class="col-xl-6">
                <div class="copyright text-center text-xl-left text-muted">
                    &copy; {{ test | date: "yyyy" }} <a href="https://www.directpay.online"
                        class="font-weight-bold ml-1" target="_blank">Powered By DPO</a>
                </div>
            </div>
            <div class="col-xl-6">
                <ul class="nav nav-footer justify-content-center justify-content-xl-end">                   
                    <li class="nav-item">
                        <a href="https://www.directpay.online/terms-conditions/" class="nav-link" target="_blank">T &amp; C's</a>
                    </li>
                    <li class="nav-item">
                        <a href="https://www.directpay.online/general-data-protection-regulation-gdpr/" class="nav-link"
                            target="_blank">GDPR</a>
                    </li>
                    <li class="nav-item">
                        <a href="https://www.directpay.online/support/" class="nav-link" target="_blank">Support</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>