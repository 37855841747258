import { IApiRequestParser } from './api.request.parser.interface';
import { Injectable } from '@angular/core';
declare var require: any;
const Parser = require('fast-xml-parser').j2xParser;

@Injectable({
    providedIn: 'root'
})
export class DPOApiRequestParser implements IApiRequestParser {

    xmlParser: any;

    constructor () {
        const defaultOptions = {
            format: true,
            indentBy: '  ',
            supressEmptyNode: false
        };
        this.xmlParser = new Parser(defaultOptions);
    }

    parseRequestToXML(request: object): string {
        const parsedString  =  this.xmlParser.parse(request);
        return parsedString;
    }

}
