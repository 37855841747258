import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SessionService } from '../services/session.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(public auth: SessionService) { }

    canActivate(): boolean {
        let authed = false;
        if (this.auth.getCompanyToken()) {
            authed = true;
        }
        return authed;
    }
}
