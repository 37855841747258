import { Requests } from '../enmus/api.requests.enums';
import { GenericDPORequest } from '../types/generic.dpo.request.type';

export class CompanyMobilePaymentOptions {

    API3G: GenericDPORequest;

    constructor(companyToken: string) {
        this.API3G = {
            CompanyToken: companyToken,
            Request: Requests.COMPANY_MOBILE_PAYMENT_OPTIONS
        };
    }
}
