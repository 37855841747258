import { Injectable } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { ToastService } from '../viewServices/toast.service';
import { ToastFrom, ToastAlign } from '../models/enmus/toast.enums';
import { TwoFactorLoginResponseType } from '../models/types/twofactorlogin.response.type';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private userToken: string;
    private companyToken: string;
    private companyCode: string;
    private companyName: string;
    private companyBrandName: string;
    private currencies: string[] = new Array;
    private username: string;
    private expirySubscription: Subscription;
    private haveNotifiedOfExpiry = false;

    constructor(private router: Router,
                private toastService: ToastService) {
        const companyToken = localStorage.getItem('companyToken');
        if (companyToken) {
            this.companyToken = companyToken;
            this.userToken = localStorage.getItem('userToken');
            this.companyName = localStorage.getItem('companyName');
            this.companyBrandName = localStorage.getItem('companyBrandName');
            this.companyCode = localStorage.getItem('companyCode');
            this.currencies = JSON.parse(localStorage.getItem('currencies'));
            this.username = localStorage.getItem('username');
        }
        this.watchRoute();
    }

    resetExpiry() {

        this.haveNotifiedOfExpiry = false;

        if (this.expirySubscription) {
            this.expirySubscription.unsubscribe();
        }

        const expiryInterval = interval(5000);
        const fiveMinsAhead = new Date().getTime() + (15 * 60000);

        this.expirySubscription = expiryInterval.pipe(
            timeout(new Date(fiveMinsAhead))
        ).subscribe(value => {
            if ((fiveMinsAhead - new Date().getTime()) <= 60000 && !this.haveNotifiedOfExpiry) {
                this.toastService.showWarning('You will be logged out in 1 minute due to inactivity', ToastFrom.TOP, ToastAlign.CENTER);
                this.haveNotifiedOfExpiry = true;
            }
        }, err => {
            if (err.name === 'TimeoutError') {
                this.clearUserSession();
                this.router.navigate(['/login']);
            }
            console.warn(err);
        });

    }

    watchRoute() {
        this.router.events.subscribe((ev: any) => {
            if (ev instanceof NavigationEnd && ev.urlAfterRedirects !== '/login') {
                this.resetExpiry();
            }
        });
    }

    setUserSession(loginResponse: TwoFactorLoginResponseType, username: string, persit: boolean) {
        this.userToken = loginResponse.API3G.UserToken;
        this.companyToken = loginResponse.API3G.CompanyToken;
        this.companyName = loginResponse.API3G.CompanyName;
        this.companyBrandName = loginResponse.API3G.CompanyBrandName;
        this.companyCode = loginResponse.API3G.CompanyCode;

        // Cater for single items returned - the parser does not treat them as single item arrays but instead a single object.
        loginResponse.API3G.Currencies.Currency.length !== 0 && typeof loginResponse.API3G.Currencies.Currency === 'string'
        ? this.currencies.push(loginResponse.API3G.Currencies.Currency as any)
        : this.currencies = [...loginResponse.API3G.Currencies.Currency];

        this.username = username;

        if (persit) {
            localStorage.setItem('userToken', this.userToken);
            localStorage.setItem('companyToken', this.companyToken);
            localStorage.setItem('companyName', this.companyName);
            localStorage.setItem('companyBrandName', this.companyBrandName);
            localStorage.setItem('companyCode', this.companyCode);
            localStorage.setItem('currencies', JSON.stringify(this.currencies));
            localStorage.setItem('username', username);
        }
    }

    clearUserSession() {
        this.userToken = null;
        this.companyToken = null;
        this.companyName = null;
        this.companyBrandName = null;
        this.companyCode = null;
        this.currencies = null;
        this.username = null;
        localStorage.removeItem('userToken');
        localStorage.removeItem('companyToken');
        localStorage.removeItem('companyName');
        localStorage.removeItem('companyBrandName');
        localStorage.removeItem('companyCode');
        localStorage.removeItem('currencies');
        localStorage.removeItem('username');
        this.expirySubscription.unsubscribe();
    }

    getCompanyToken(): string {
        return this.companyToken;
    }

    getUserToken(): string {
        return this.userToken;
    }

    getUserName(): string {
        return this.username;
    }

    getCompanyCode(): string {
        return this.companyCode;
    }

    getCompanyBrandName(): string {
        return this.companyBrandName;
    }

    getCurrencies(): string[] {
        return this.currencies;
    }
}
