import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

export declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class?: string;
    shouldShowInNav: boolean;
}
export const ROUTES: RouteInfo[] = [
    { path: '/payment', title: 'Create Payment',  icon: 'ni-money-coins text-primary', shouldShowInNav: true },
    { path: '/transactions', title: 'Payments Log',  icon: 'ni-book-bookmark text-primary', shouldShowInNav: true },
    { path: '/details', title: 'Payments Details',  icon: '', shouldShowInNav: false },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Input()companyBrandName;

  public menuItems: any[];
  public isCollapsed = true;
  public companyNamePrompt: string;

  constructor(private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });

   this.companyNamePrompt = this.companyBrandName;

  }
}
