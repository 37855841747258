import { IApiResponseParser } from './api.response.parser.interface';
import { Injectable } from '@angular/core';

declare var require: any;
const Parser = require('fast-xml-parser');

@Injectable({
    providedIn: 'root'
})
export class DPOApiResponseParser implements IApiResponseParser {

    parseXMLStringToJSON(xml: string) {
        const options = {
            ignoreAttributes : true,
            ignoreNameSpace : false,
            allowBooleanAttributes : false,
            parseNodeValue : true,
            parseAttributeValue : false,
            trimValues: true,
            parseTrueNumberOnly: true,
            arrayMode: false, // "strict"
        };

        const convertedOBJ = Parser.parse(xml, options);

        return convertedOBJ;
    }
}
