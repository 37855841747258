export enum Requests {
    LOGIN = 'usrAuth',
    CREATE_TOKEN = 'createToken',
    UPDATE_TOKEN = 'updateToken',
    GET_TRANSACTIONS = 'getTransactions',
    GET_TRANSACTIONS_COUNT = 'getTransactionsCount',
    CHARGE_TOKEN_MOBILE = 'ChargeTokenMobile',
    CHARGE_TOKEN_AUTH = 'chargeTokenAuth',
    VERIFY_TOKEN = 'verifyToken',
    GET_TRANSACTIONS_BY_REF = 'getTransactionByRef',
    EMAIL_TO_TOKEN = 'emailToToken',
    GET_SERVICES = 'getServices',
    COMPANY_MOBILE_PAYMENT_OPTIONS = 'CompanyMobilePaymentOptions'
}
