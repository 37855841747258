import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DPOApiResponseParser } from '../models/parsers/dpo.api.response.parser';
import { DPOApiRequestParser } from '../models/parsers/dpo.api.request.parser';
import { SessionService } from './session.service';

@Injectable({
    providedIn: 'root'
})
export class DPOApiService {
    apiURL: string;
    httpOptions = {};

    constructor(
        private httpClient: HttpClient,
        private respParser: DPOApiResponseParser,
        private requestParser: DPOApiRequestParser,
        private sessionService: SessionService
    ) {
        this.apiURL = environment.apiUrl;
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/xml'
            }),
            responseType: 'text/html; charset=UTF-8'
        };
    }

    public HTTPPost(payload: any, apiVersion: string = '/V6'): Observable<any> {
        this.sessionService.resetExpiry();
        const parsedPayload = this.requestParser.parseRequestToXML(payload);

        return this.httpClient.post(`${this.apiURL}${apiVersion}`, parsedPayload, this.httpOptions).pipe(
            map((resp: any) => {
                return this.respParser.parseXMLStringToJSON(resp);
            })
        );
    }
}
